import { Component, HostListener } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { CommonModule, NgIf, DecimalPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-spruce-servicing',
  standalone: true,
  templateUrl: './spruce-servicing.component.html',
  styleUrl: './spruce-servicing.component.scss',
  imports: [HeaderComponent, FooterComponent, CommonModule, NgIf, DecimalPipe, RouterLink]
})
export class SpruceServicingComponent {

  animatedNumber_Customers: number = 0;
  targetNumber_Customers: number = 80000;
  duration_Customers: number = 3000; // Duration of the animation in milliseconds
  startTime_Customers: number | null = null;
  show_Customers: boolean = false;

  animatedNumber_Assets: number = 0;
  targetNumber_Assets: number = 1000000000;
  duration_Assets: number = 5000; // Duration of the animation in milliseconds
  startTime_Assets: number | null = null;
  show_Assets: boolean = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    const element = document.querySelector('.number-container');
    if (element) {
      const rect = element.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        this.animateNumber_Customers();
        this.animateNumber_Assets();
      }
    }
  }

  animateNumber_Customers(): void {
    if (this.startTime_Customers === null) {
      this.startTime_Customers = performance.now();
    }
    const currentTime = performance.now();
    const elapsedTime = currentTime - this.startTime_Customers;
    const progress = Math.min(elapsedTime / this.duration_Customers, 1);
    this.animatedNumber_Customers = Math.floor(progress * this.targetNumber_Customers);

    if (progress < 1) {
      requestAnimationFrame(() => this.animateNumber_Customers());
    }
    else {
      this.show_Customers = true;
    }
  }

  animateNumber_Assets(): void {
    if (this.startTime_Assets === null) {
      this.startTime_Assets = performance.now();
    }
    const currentTime = performance.now();
    const elapsedTime = currentTime - this.startTime_Assets;
    const progress = Math.min(elapsedTime / this.duration_Assets, 1);
    this.animatedNumber_Assets= Math.floor(progress * this.targetNumber_Assets);

    if (progress < 1) {
      requestAnimationFrame(() => this.animateNumber_Assets());
    }
    else {
      this.show_Assets = true;
    }
  }

}
 