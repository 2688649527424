<app-header></app-header>

<div class="main">
  <div class="services-banner-section">
    <div class="services-section-banckground-image">
      <div class="services-banner-image">
        <div class="container">
          <div class="banner-services-logo">
            <img
              decoding="async"
              src="/assets/Spruce-Servicing.png"
              alt="Spruce-Pro-Logo_Lockup_Large"
            />
            <div class="banner-services-heading">
              <h1>Your Portfolio<br />Servicing Partner</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-section">
    <div class="container">
      <div class="our-customers-section">
        <div class="text-left">
          <div class="portfolio-heading">
            <h1>We are a leading portfolio servicing solutions provider.</h1>
            <p>
              Backed by the expertise of financial, solar, and utility
              professionals we bring an industry-leading network of service and
              maintenance partners. We offer customers a comprehensive,
              end-to-end servicing solution.
            </p>
          </div>
        </div>
        <div class="row ms-0 me-0 mt-5 pt-0 pb-5 pt-lg-5 mt-lg-5">
          <div class="col-12 col-lg-6 ps-0 pe-0 text-center">
            <div class="">
              <div class="percent">
                <p>
                  <span
                    class="percent-value number-container"
                    (window:scroll)="onScroll()"
                  >
                    <span *ngIf="!show_Customers">{{
                      animatedNumber_Customers | number : "1.0-0"
                    }}</span>
                    <span *ngIf="show_Customers"
                      >{{ targetNumber_Customers | number : "1.0-0" }}+
                    </span> </span
                  ><span class="percent-sign"></span>
                </p>
              </div>
              <h5 class="title">energy customers across the United States.</h5>
              <canvas height="0" width="0"></canvas>
            </div>
          </div>
          <div class="col-12 col-lg-6 ps-0 pe-0 text-center">
            <div class="percent">
              <p>
                <span class="percent-value">
                  <span *ngIf="!show_Assets">{{
                    animatedNumber_Assets | number : "1.0-0"
                  }}</span>
                  <span *ngIf="show_Assets">$1 billion</span> </span
                ><span class="percent-sign"></span>
              </p>
            </div>
            <h5 class="title">in assets currently being serviced.</h5>
            <canvas height="0" width="0"></canvas>
          </div>
          <div class="divider-portfolio col-12 ps-0 pe-0"></div>
          <div class="col-12 p-0">
            <div class="row ms-0 me-0 mt-4 mt-lg-5 pt-lg-5">
              <div class="col-12 col-lg-6 ps-0 pe-0">
                <div class="protfolio-services-chart">
                  <img
                    src="/assets/Spruce-Servicing-Comparison-Chart-scaled.png"
                    alt="chart"
                  />
                </div>
              </div>
              <div class="col-12 col-lg-6 ps-0 pe-0">
                <div class="mt-4 mt-lg-5 pt-3 ms-0 ms-md-4 ms-lg-5">
                  <h2 class="full-scope-heading">Full-Scope Servicing</h2>
                  <p class="mt-4">
                    Spruce Servicing’s breadth of services enables a unique
                    full-scope servicing solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="services-card-section">
    <div class="first-wrap">
      <div class="first-div">
        <div class="first-inner-div">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-4 py-3">
                <div class="card-services">
                  <div class="card-main">
                    <h4 class="card_header">Financial Asset Management</h4>
                    <div class="card_description">
                      <p>
                        In-depth financial services that span financial and
                        operating reports, analysis, waterfalls & cash
                        distributions, lender and tax equity partner management,
                        tax services, and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 py-3">
                <div class="card-services">
                  <div class="card-main">
                    <h4 class="card_header">Billing/Collections</h4>
                    <div class="card_description">
                      <p>
                        Easy payment options and billing portal for portfolio
                        customers along with streamlined payment processing,
                        account monitoring, and end-to-end collections
                        activities to ensure healthy and current accounts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 py-3">
                <div class="card-services">
                  <div class="card-main">
                    <h4 class="card_header">Account Services</h4>
                    <div class="card_description">
                      <p>
                        Management of the entire customer account from start to
                        finish, from UCC/fixture filing removals and system
                        buyouts to performance guarantees and other end to end
                        account services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 py-3">
                <div class="card-services">
                  <div class="card-main">
                    <h4 class="card_header">Asset Operations</h4>
                    <div class="card_description">
                      <p>
                        System monitoring and performance management tools
                        combined with a broad network of partners for timely
                        on-site maintenance or repair helps sustain
                        high-performing assets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 py-3">
                <div class="card-services">
                  <div class="card-main">
                    <h4 class="card_header">Homeowner Support</h4>
                    <div class="card_description">
                      <p>
                        Dedicated US based homeowner support team and 24×7
                        online portal to answer homeowner questions on their
                        agreements, system performance, home sales, refinance,
                        and agreement transfers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 py-3">
                <div class="card-services">
                  <div class="card-main">
                    <h4 class="card_header">Servicing IT</h4>
                    <div class="card_description">
                      <p>
                        From a full-service customer facing portal to intuitive
                        reporting, Spruce Servicing offers full-stack technology
                        implementation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="financial-asset">
    <div class="container">
      <div class="row mt-5 mb-5 ms-0 me-0">
        <div class="col-12 col-lg-5 ps-0 pe-0">
          <div class="financial-left-image">
            <img src="/assets/cover4.jpeg" />
          </div>
        </div>
        <div class="col-12 col-lg-7 ps-0 pe-0">
          <div class="mt-5 mt-lg-0">
            <div class="et_pb_text_inner">
              <h2 class="card_header" style="text-align: center">
                Financial Asset<br />Management Services
              </h2>
            </div>
            <p class="mt-5">
              The proof is in the metrics. Through in- depth financial analysis
              and reporting, we’re able to provide detailed records for asset
              owners and 3rd party funds on financial and operational reporting
              metrics.
            </p>
            <div class="et_pb_text_list mt-5">
              <ul>
                <li>Monthly/quarterly operating reports</li>
                <li>Audited financials</li>
                <li>Waterfall and cash distribution management</li>
                <li>Lender &amp; tax equity partner management</li>
                <li>Invoice payment and processing</li>
                <li>Tax Preparation</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 ps-0 pe-0 mt-0 mt-lg-5 pt-0 pt-lg-5">
          <div class="billing-text">
            <h2 class="card_billing" style="text-align: center">Billing/Collections</h2>
          </div>
          <div class="inner-billing-section row ms-0 me-0">
            <div class="col-md-12 col-lg-4 p-0 p-lg-3">
              <div class="mt-2 mt-lg-0">
                <div class="billing-inner-heading">
                  <strong>Billing</strong>
                </div>
                <p class="mt-3">
                  We’re committed to making the customer’s payment process
                  hassle-free. We offer easy payment options for customers.
                </p>
                <div class="et_pb_text_list mt-4">
                  <ul>
                    <li>
                      Seamless remittance management and cash application based
                      on the appropriate portfolio designation.
                    </li>
                    <li>
                      A coordinated collections program that monitors accounts,
                      mitigates account delinquency, ensures maximum values are
                      recaptured, and efficiently converts accounts to a current
                      status.
                    </li>
                    <li>
                      A self-service Billing portal for the customer which
                      allows them to manage payments and preferences.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 p-0 p-lg-3">
              <div class="mt-2 mt-lg-0">
                <div class="billing-inner-heading">
                  <strong>Asset Recovery</strong>
                </div>
                <p class="mt-3">
                  The Spruce Servicing Asset Recovery team recovers delinquent
                  balances while reducing the volume of future delinquent
                  accounts.
                </p>
                <div class="et_pb_text_list mt-4">
                  <ul>
                    <li>
                      Our collections specialists are real-time problem solvers
                      focused on determining the root cause of delinquency.
                    </li>
                    <li>
                      We are dedicated to helping debtors find sustainable
                      solutions while addressing customer concerns real-time for
                      rapid delinquent balance recoveries.
                    </li>
                    <li>
                      We use industry leading collection and telephony systems
                      to maximize customer outreach, comply with regulatory
                      bodies and capture critical data for insights and
                      performance management.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 p-0 p-lg-3">
              <div class="mt-2 mt-lg-0">
                <div class="billing-inner-heading">
                  <strong>Fit-For-Purpose Solar Servicing</strong>
                </div>
                <p class="mt-3">
                  We are uniquely positioned to resolve underlying solar-related
                  technical and commercial drivers of delinquencies:
                </p>
                <div class="et_pb_text_list mt-4">
                  <ul>
                    <li>
                      Co-located with Customer Support and Asset Operations
                      Teams.
                    </li>
                    <li>
                      Can address issues real-time for rapid delinquent balance
                      recoveries.
                    </li>
                    <li>
                      Fit for purpose solution for managing residential solar
                      delinquencies.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="account-services-section">
    <div class="background-mask-services">
      <div class="container inner-account-services">
        <div class="row">
          <div class="col-12 col-lg-8">
            <div class="account-services-heading">
              <h2>Account Services</h2>
            </div>
            <div class="paragraph-account-services">
              <p>
                Beyond just account support, we provide account solutions. Our
                account <br />
                services include complete customer account management from start
                to finish. <br />
                From UCC and fixture filing management and SREC reporting to
                performance <br />
                guarantee and system buyout management, our team has the entire
                account <br />
                spectrum covered, including:
              </p>
              <p>&nbsp;</p>
              <div class="et_pb_text_list">
                <ul>
                  <li>Onboarding and booking</li>
                  <li>Transaction support</li>
                  <li>Incentive management</li>
                  <li>Quality assurance</li>
                  <li>Guarantee management</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="homeowners-section">
    <div class="container ps-0 pe-0">
      <div class="row ms-0 me-0">
        <div class="col-12 col-lg-4 customer-heading">
          <h2 style="text-align: center; line-height: 1.2em">
            Customer &amp;<br />Homeowner Support
          </h2>
          <div class="phone-icon text-center mt-5 mb-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="100"
              height="100"
            >
              <path
                d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
              />
            </svg>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <div class="mt-2 mt-lg-0">
            <p>
              Our customer and US based homeowner support team is the primary
              point of contact for customers after system installation and
              activation. They use a cutting-edge customer relationship
              management system.
            </p>
            <div class="et_pb_text_list mt-4">
              <p>Customer inquiries managed by the team include:</p>
              <ul>
                <li>Home Sales</li>
                <li>Refinances</li>
                <li>Billing & Monitoring</li>
                <li>General Inquiries</li>
                <li>Escalated Customer Management</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="asset-operations-sections">
    <div class="container text-center inner-asset-text">
      <h2>Asset Operations</h2>
      <p>
        Leave the system maintenance and technical support to us. Through a
        combination of system monitoring and performance management tools, our
        asset operations team is able to determine when a solar system needs
        technical support. Our network of high-quality service providers and
        solar professionals support the following maintenance activities
      </p>
      <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
        <div class="accordion-item">
          <h5 class="accordion-header mb-0" id="flush-headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Data Aggregation & Monitoring
            </button>
          </h5>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <div class="et_pb_text_list">
                <ul>
                  <li>
                    Our technical experts remotely diagnose system performance
                    issues.
                  </li>
                  <li>
                    Data is monitored for underperformance, zero generation, and
                    communication issues.
                  </li>
                  <li>
                    Utilization of automated queries and advanced data analytics
                    streamlines the monitoring process.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h5 class="accordion-header mb-0" id="flush-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              Performance Management
            </button>
          </h5>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <div class="et_pb_text_list">
                <ul>
                  <li>
                    Our technical experts remotely diagnose system performance
                    issues.
                  </li>
                  <li>
                    Multiple inputs are incorporated into performance analysis
                    including weather data, inverter level performance data,
                    shade study data and system design details.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h5 class="accordion-header mb-0" id="flush-headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              Dispatch & Work Orders
            </button>
          </h5>
          <div
            id="flush-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <div class="et_pb_text_list">
                <ul>
                  <li>
                    The team administers work orders using a cloud-based
                    maintenance management system.
                  </li>
                  <li>
                    If on-site support is required, a dispatch is sent to a
                    field technician within our nationwide network of service
                    providers.
                  </li>
                  <li>
                    Service providers facilitate system repairs and manage the
                    equipment warranty process.
                  </li>
                  <li>
                    Workmanship warranties and contractual obligations are
                    maximized with initial installers to ensure value retention
                    for portfolio owners.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="spruce-servicing-section">
    <div class="spruce-servicing-background">
      <div class="spruce-servicing-button">
        <a [routerLink]="['/contact-us']"
          >Connect with Spruce Servicing</a
        >
      </div>
    </div>
    <div class="leaderShip-section">
      <div class="container">
        <div class="leaderShip-text">
          <h2 class="name-leaderShip">Leadership Team</h2>
        </div>
        <div class="list-leaderShip">
          <div class="inner-leaderShip row mt-0 mt-lg-5 ms-0 pe-0">
            <div class="col-12 col-lg-4 ps-0">
              <div class="et_pb_blurb_content text-center">
                <div class="leadership-image">
                  <img src="/assets/Derick_Website-copy.jpg" />
                </div>
                <div class="et_pb_blurb_container">
                  <h4 class="mt-3 leader-name">Derick Smith</h4>
                  <div class="et_pb_blurb_description">
                    <p>Chief Operating Officer, Spruce Servicing</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 ps-0">
              <div class="et_pb_blurb_content text-center">
                <div class="leadership-image">
                  <img src="/assets/Chris_Hayes_Square-copy.jpg" />
                </div>
                <div class="et_pb_blurb_container">
                  <h4 class="mt-3 leader-name">Chris Hayes</h4>
                  <div class="et_pb_blurb_description">
                    <p>Chief Executive Officer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="inner-leaderShip row mt-5 ms-0 pe-0">
            <div class="col-12 col-lg-3 ps-0">
              <div class="et_pb_blurb_content text-center">
                <div class="leadership2-image">
                  <img src="/assets/Arnold-Ybanez-Call-Center-Operations.png" />
                </div>
                <div class="et_pb_blurb_container">
                  <h4 class="mt-3 leader-name">Arnold Ybanez</h4>
                  <div class="et_pb_blurb_description">
                    <p>Senior Director, Customer Support</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3 ps-0">
              <div class="et_pb_blurb_content text-center">
                <div class="leadership2-image">
                  <img src="/assets/Sophia-Washington-Spruce-Pro.png" />
                </div>
                <div class="et_pb_blurb_container">
                  <h4 class="mt-3 leader-name">Sophia Washington</h4>
                  <div class="et_pb_blurb_description">
                    <p>VP, IT & Enterprise Applications</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3 ps-0">
              <div class="et_pb_blurb_content text-center">
                <div class="leadership2-image">
                  <img src="/assets/Dan-Dadufalza-Asset-Recovery.png" />
                </div>
                <div class="et_pb_blurb_container">
                  <h4 class="mt-3 leader-name">Dan Dadufalza</h4>
                    <div class="et_pb_blurb_description">
                      <p>Director, Asset Recovery</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
